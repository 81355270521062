import { writable, readable } from 'svelte/store';
import SECTORS from '@/data/data_sectors.csv'
import { projectionKeys } from '@/constants.js'
import * as langHandler from '@/utils/language-handler.js';


const sectorList = SECTORS.map((d) => ({
  id: d.sector, 
  name: langHandler.getSectorName(d.sector), 
  label: langHandler.getSectorName(d.sector), 
  unavailable: false
}))

console.log("SECTORS")
console.log(SECTORS)
console.log(sectorList)

const projectionList = projectionKeys.map(d => ({
  id: d,
  name: langHandler.getTranslation(d),
  label: langHandler.getTranslation(d),
  suffix: langHandler.getTranslation(d + "_suffix"),
}))

export const sectorOptions = readable(sectorList)
export const selectedSector = writable(sectorList[0]);


export const projectionOptions = readable(projectionList);
export const selectedProjection = writable(projectionList[0]);
