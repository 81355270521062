export const projectionKeys = ["ERP_legislated", "ERP_developing", "ERP_announced_less", "ERP_announced_more"]


export const chartColours = {
    neutrals: {
      'line': '#233e43',
      'background': '#F5FAF8'
    },
    targets: {
      'miss': '#f94346',
      'meet': '#328069'
    },
    scenarios: {
      'historical': 'hsl(189, 31%, 20%)', //'#233E43', 
      'ERP_legislated': 'hsl(189, 31%, 20%)',//'#233E43',//'#000', 
      'ERP_developing': 'hsl(189, 31%, 30%)',//'#233E43',//'#777', 
      'ERP_announced': '#233E43',//'#aaa', 
      'ERP_announced_more': 'hsl(189, 15%, 60%)',//'#688987',//'#aaa', 
      'ERP_announced_less': 'hsl(189, 27%, 40%)',//'#233E43',//'#aaa', 
      'NZP_upper': '#C0DFD6',
      'NZP_lower': '#C0DFD6',
      'NZP_range': '#C0DFD6'
    },
    drivers: {
      'activity': '#FF6E1C',
      'efficiency': '#3F6A91',
      'cleaner': '#43AA8B'
    },
  energy: {
    'share_emitting': '#CCCCCC',
    'share_nonemitting': '#43AA8B'
  }
}

export const lineOpacities = {
  scenarios: {
    'historical': 1, 
    'ERP_legislated': 0.8,
    'ERP_developing': 0.8, //0.8,  
    'ERP_announced_more': 0.8, //0.65,
    'ERP_announced_less': 0.8 //0.65,
  }
}

export const years = {
  historical: [2005, 2023],
  projection: [2023, 2030],
  netZero: [2030, 2050]
}

// export const NZPtooltipText = "In the chart above, the net zero pathway is a range, based on aggregating ranges of sub-sector emissions pathways. In the Institute's report, <i>Canada’s Net Zero Future</i>, a single national emissions pathway was used to hit the 2030 target and net zero by 2050."
//"Here, the net zero pathway is presented as a range, constructed based on aggregating ranges of sub-sectors emissions pathways from the Institute's 62 net zero scenarios. In the Institute's report, <span class='report-name'>Canada’s Net Zero Future</span>, a single national emissions pathway was used across all scenarios to hit the 2030 target and to achieve net zero by 2050."