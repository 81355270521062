<script>
  import * as langHandler from '@/utils/language-handler.js';
  import mq from "@/stores/mq.js";
  import { onMount } from "svelte";
  import sanitizeHtml from "sanitize-html";
  import { format } from 'd3-format';

  // Decarbonization dashboard
  import ChartSection from "./ChartSection.svelte";
  import LineChart from "./Chart__Line.svelte";
  import StackedAreaChart from "./Chart__Stacked.svelte";
  import SectorDropdown from "./SectorDropdown.svelte"
  import ScenarioSelector from "./ScenarioSelector.svelte";
  import BottomNav from './BottomNav.svelte';
  import InfoTooltip from "./InfoTooltip.svelte";

  import { selectedSector, selectedProjection } from '@/stores/interactivity.js'
  import { chartColours, years, projectionKeys } from '@/constants.js';
  import { ghgData, getValue } from '@/stores/data.js'
  import { formatDefaultVal, formatPercentageTracker, addNZPRange } from "@/utils/dataHelpers.js"

  let comparisonView = false;

  const processGHGdata = (_, sector) => {
    let data = _.map(x => x).filter(d => d.sector === sector) // make a shallow copy
    data = addNZPRange(data, "ghg_per_energy");
    // Commented out Oct 21 -- should now be included in the data.
    // data.forEach((d) => {
    //   d.share_emitting = d.energy_emitting / d.energy;
    //   d.share_nonemitting = d.energy_nonemitting / d.energy;
    // })
    return data;
  }

  console.log("DECARB")
  console.log(years)
  console.log($selectedProjection.id)

  $: processedGHGData = $ghgData ? processGHGdata($ghgData, $selectedSector.id) : null
  $: projectionVars = comparisonView ? projectionKeys : [$selectedProjection.id]

  onMount(() => {
    const headerHeight = document.getElementsByClassName("site-header")[0].offsetHeight
    if (window.scrollY > headerHeight) {
      window.scrollTo(0,headerHeight);
    } 
  });

  const projectionStartYear = Number(years.projection[0])

  $: targetNonemittingValue = $getValue($selectedSector.id, 2030, d => d["share_nonemitting"], "NZP_upper");
  $: actualNonemittingValue= $getValue($selectedSector.id, 2030, d =>d["share_nonemitting"], $selectedProjection.id);

  $: targetFootprintChange = ($getValue($selectedSector.id, 2030, d => d["ghg_per_energy"], "NZP_upper") - getFootprintValueByYr(projectionStartYear))/ Math.abs(getFootprintValueByYr(projectionStartYear));
  $: actualFootprintChange= ($getValue($selectedSector.id, 2030, d =>d["ghg_per_energy"], $selectedProjection.id) - getFootprintValueByYr(projectionStartYear))/ Math.abs(getFootprintValueByYr(projectionStartYear));

  $: getNonemittingValueByYr = year => $getValue($selectedSector.id, year, d => d["share_nonemitting"], year < years.projection[0] ? "historical" : $selectedProjection.id);
  $: formatNonemittingVal = d => format(",.1%")(d);

  $: getFootprintValueByYr = year => $getValue($selectedSector.id, year, d => d["ghg_per_energy"], year < years.projection[0] ? "historical" : $selectedProjection.id);
  $: formatFootprintVal = d => formatDefaultVal(d) + ' ' + langHandler.getUnits('ghg_per_energy_short')

</script>


<div class="text-wrapper">
  <div class="centered-text">
    {#if langHandler.locale == 'fr'}
      <h1 class="section-title" style="max-width:500px;">La rôle de <span class="cleaner">la décarbonisation</span> in reducing emissions</h1>
      <p>Dans de nombreux secteurs, la décarbonisation – la transition des combustibles fossiles aux sources d’énergie propre, ainsi que le déploiement des technologies sobres en carbone – est le facteur le plus décisif pour l’atteinte des cibles climatiques du Canada.</p>
    {:else}
      <h1 class="section-title" style="max-width:500px;">Role of <span class="cleaner">decarbonization</span> in reducing emissions</h1>
      <p>In many sectors, decarbonization—switching from fossil fuels to cleaner sources of energy, as well as deploying low-emitting technology—is the most significant driver for reaching Canada’s climate targets.</p>
    {/if}
  </div>
</div>

<ChartSection title={langHandler.getTranslation('share_energy_for')} units={langHandler.getUnits('share_energy')}
  chartOffsetLeft={$mq.laptop ? 0 : '4ch'}>
  <span slot="sector-dropdown">
    <SectorDropdown/>
  </span>
  <span slot="chart">
  {#if processedGHGData}
    <!-- Changing nonemitting to be on the bottom because of the new copy -->
  <StackedAreaChart data={processedGHGData} 
    seriesNames={["share_nonemitting", "share_emitting"]}
    xKey={"year"}
    xDomain={[years.historical[0], years.projection[1]]}
    zKey={"scenario"} 
    zDomain={["historical", $selectedProjection.id]} 
    highlight={{fill: chartColours.drivers['cleaner'], opacity: 0.08}}
    />
  {:else}
    <div class="loading-text">{langHandler.getTranslation('loading_chart')}</div>
  {/if}
  </span>
  <span slot="legend">
    <ScenarioSelector/>
  </span>
  <span class="source" slot="source">
    Sources: <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('statscan')}>{langHandler.getTranslation('statscan')}</a>, <i>Navius Research (2022)</i>.
  </span>
  <span slot="caption">
    {#if langHandler.locale == 'fr'}
      <p>
        Selon le scénario des <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix} du Plan de réduction des émissions, la part des sources d’énergie propre du {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} devrait 
        {#if getNonemittingValueByYr(2030) - getNonemittingValueByYr(projectionStartYear) === 0 }
          rester constante <strong>{formatNonemittingVal(getNonemittingValueByYr(projectionStartYear))} entre {projectionStartYear} et 2030</strong>.
        {:else}
          {getNonemittingValueByYr(2030) - getNonemittingValueByYr(projectionStartYear) >0 ? 'augmenter' : 'diminuer'}, passant de {formatNonemittingVal(getNonemittingValueByYr(projectionStartYear))} en {projectionStartYear} à <strong>{formatNonemittingVal(getNonemittingValueByYr(2030))} en 2030</strong>.
        {/if}
      </p>
      <p>
        Pour être en phase avec la trajectoire carboneutre, la part d’énergie propre doit atteindre {formatNonemittingVal(targetNonemittingValue)} d’ici 2030.  
        Or, en s’appuyant sur les {$selectedProjection.name?.toLowerCase()}, on prévoit qu’elle atteindra {formatNonemittingVal(actualNonemittingValue)}, 
        {#if actualNonemittingValue < targetNonemittingValue} 
          un pourcentage <span class="tracker-text" style='color:{chartColours.targets['miss']}'>insuffisant dans la trajectoire carboneutre</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip>
        {:else}
          l'alignant <span class="tracker-text" style='color:{chartColours.targets['meet']}'>sur la trajectoire carboneutre</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> 
        {/if}
      </p>
    {:else}
      <p>
        Under the Emissions Reduction Plan’s <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix}, the share of non-emitting energy sources in {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} is expected to
        {#if getNonemittingValueByYr(2030) - getNonemittingValueByYr(projectionStartYear) === 0 }
          stay constant at <strong>{formatNonemittingVal(getNonemittingValueByYr(projectionStartYear))} between {projectionStartYear} and 2030</strong>.
        {:else}
          {getNonemittingValueByYr(2030) - getNonemittingValueByYr(projectionStartYear) >0 ? 'increase' : 'decrease'} from {formatNonemittingVal(getNonemittingValueByYr(projectionStartYear))} in {projectionStartYear} to <strong>{formatNonemittingVal(getNonemittingValueByYr(2030))} in 2030</strong>.
        {/if}
      </p>
      <p>
        To be aligned with a net zero pathway, the share of non-emitting energy must reach {formatNonemittingVal(targetNonemittingValue)} in 2030. 
        With {$selectedProjection.name?.toLowerCase()}, the share is projected to {actualNonemittingValue - getNonemittingValueByYr(projectionStartYear) >0 ? 'increase' : 'decrease'} to {formatNonemittingVal(actualNonemittingValue)}, putting it
        {#if actualNonemittingValue < targetNonemittingValue} 
          <span class="tracker-text" style='color:{chartColours.targets['miss']}'>behind the net zero pathway</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip>
        {:else}
          <span class="tracker-text" style='color:{chartColours.targets['meet']}'>on track with a net zero pathway</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> 
        {/if}
      </p>
    {/if}
</span>
</ChartSection>

<div class="text-wrapper">
  <div class="centered-text">
    {#if langHandler.locale == 'fr'}
      <p>La transition vers l’énergie propre n’est toutefois pas la seule stratégie de décarbonisation. Des technologies vertes comme la thermopompe et les véhicules électriques permettent de décarboniser les procédés de production et de réduire les émissions issues des maisons et des véhicules.</p>
      <p>L’effet combiné des technologies propres et des combustibles à faibles émissions peut s’exprimer en émissions totales par unité d’énergie, soit l’intensité carbonique de l’énergie. Une intensité carbonique peu élevée est préférable.</p>
    {:else}
      <p>Switching to non-emitting energy is not the only way to decarbonize energy, however. Cleaner technologies like heat pumps and electric vehicles allow us to decarbonize production processes and reduce emissions from our homes and vehicles.</p>
      <p>The combined effect of cleaner technologies and lower-emitting fuels can be measured as a change in total emissions per unit of energy, or the carbon intensity of energy. The lower the carbon intensity, the better.</p>
    {/if}
  </div>
</div>


<ChartSection title={langHandler.getTranslation('carbon_intensity_of_energy_for')} units={langHandler.getUnits('ghg_per_energy')}
  chartOffsetLeft={$mq.laptop ? 0 : '3ch'}>
  <span slot="sector-dropdown">
    <SectorDropdown/>
  </span>
  <span slot="chart">
  {#if processedGHGData}
  <!-- Use a yAccessor instead of a key here -->
  <LineChart data={processedGHGData} 
    includesRanges={true}
    xKey={"year"}
    xDomain={[years.historical[0], years.projection[1]]}
    yKey={"ghg_per_energy"}
    targetValue={$getValue($selectedSector.id, 2030, d => d["ghg_per_energy"], "NZP_upper")}
    actualValue={$getValue($selectedSector.id, 2030, d => d["ghg_per_energy"], $selectedProjection.id)}
    stroke={chartColours.drivers['cleaner']}
    highlight={{fill: chartColours.drivers['cleaner'], opacity: 0.1}}
    zKey={"scenario"} 
    zDomain={["historical", "NZP_upper", "NZP_lower", "NZP_range", ...projectionVars]} 
    tooltipUnits={langHandler.getUnits('ghg_per_energy_short')}
    tooltipVarName={langHandler.getTranslation('carbon_intensity')}
    />
  {:else}
    <div class="loading-text">{langHandler.getTranslation('loading_chart')}</div>
  {/if}
  </span>
  <span slot="legend">
    <ScenarioSelector allowComparison=true bind:comparisonView/>
  </span>
  <span class="source" slot="source">
    Sources: <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('statscan')}>{langHandler.getTranslation('statscan')}</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.naviusresearch.com/">Navius Research</a>.
  </span>
  <span slot="caption">
    {#if langHandler.locale == 'fr'}
      <p>
        Selon le scénario des <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix} du Plan de réduction des émissions, l’intensité carbonique de l’énergie du {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} devrait
        <strong>{getFootprintValueByYr(2030) - getFootprintValueByYr(projectionStartYear) >0 ? 'augmenter' : 'diminuer'} de {formatFootprintVal(Math.abs(getFootprintValueByYr(2030) - getFootprintValueByYr(projectionStartYear)))}</strong> de {projectionStartYear} à 2030.
      </p>
      <p>
        Pour être en phase avec la trajectoire carboneutre, l’intensité des émissions doit diminuer de {formatPercentageTracker(-1*(targetFootprintChange))} de {projectionStartYear} à 2030. 
        En s’appuyant sur les {$selectedProjection.name?.toLowerCase()}, on prévoit que le secteur {actualFootprintChange >0 ? 'increase' : 'diminuera'} de {formatPercentageTracker(Math.abs(actualFootprintChange))}, 
        {#if actualFootprintChange > targetFootprintChange} 
          <span class="tracker-text" style='color:{chartColours.targets['miss']}'>un manque de gagner de {formatPercentageTracker(Math.abs(actualFootprintChange - targetFootprintChange))} par rapport à la trajectoire carboneutre</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip>
        {:else}
          ce qui le met <span class="tracker-text" style='color:{chartColours.targets['meet']}'>sur la voie de l'objectif carboneutre</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> 
        {/if}
      </p>
    {:else}
      <p>
        Under the Emissions Reduction Plan’s <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix}, the carbon intensity of energy for {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} is expected to
        <strong>{getFootprintValueByYr(2030) - getFootprintValueByYr(projectionStartYear) >0 ? 'increase' : 'decrease'} by {formatFootprintVal(Math.abs(getFootprintValueByYr(2030) - getFootprintValueByYr(projectionStartYear)))}</strong> from {projectionStartYear} to 2030.
      </p>
      <p>
        To be aligned with a net zero pathway, emissions intensity must decrease by {formatPercentageTracker(-1*(targetFootprintChange))} between {projectionStartYear} and 2030. 
        With {$selectedProjection.name?.toLowerCase()}, the sector is projected to {actualFootprintChange >0 ? 'increase' : 'decrease'} by {formatPercentageTracker(Math.abs(actualFootprintChange))}, putting it
        {#if actualFootprintChange > targetFootprintChange} 
          <span class="tracker-text" style='color:{chartColours.targets['miss']}'>{formatPercentageTracker(Math.abs(actualFootprintChange - targetFootprintChange))} behind the net zero pathway</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip>
        {:else}
          <span class="tracker-text" style='color:{chartColours.targets['meet']}'>on track with a net zero pathway</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> 
        {/if}
      </p>
    {/if}
</span>
</ChartSection>
<BottomNav page="decarbon"/>

<style>
  .report-name, .source a {
    font-style: italic;
  }

  ul li {
    margin-left: 1em;
  }

  .tracker-text {
    font-weight: 600;
  }

  .activity {
    color: var(--orange-medium);
  }

  .efficiency {
    color: var(--blue);
  }

  .cleaner {
    color: var(--turquoise-medium);
  }

  .text-wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  
  .text-wrapper {
    font-family: var(--serif);
  }

  h1, .section-title {
    font-family: var(--condensed);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3;
    color: var(--neutral);
  }

  .section-title {
    text-align: center;
    margin: 0 auto;
    margin-top: 32px;
  }

  .section-title.fr {
    max-width: 30ch;
  }
  
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .loading-text {
    height: 350px; /* same as chart-container */
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }
</style>
