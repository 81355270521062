<script>
  import * as langHandler from '@/utils/language-handler.js';
  import mq from "@/stores/mq.js";

  import { LayerCake, Svg, Html } from 'layercake';
  import { groups, extent, merge } from 'd3-array';
  import { scaleLinear, scalePoint } from 'd3-scale';
  import { chartColours, years } from '@/constants.js';
  import { formatDefaultVal } from "@/utils/dataHelpers.js"

  import Arrow from './chart_parts/Arrow.svelte';
  import ArrowDots from './chart_parts/ArrowDots.svelte';
  import ArrowLabels from './chart_parts/ArrowLabels_html.svelte';

  import ArrowAnnotations from './chart_parts/ArrowAnnotations_html.svelte';

  import Tooltip from "./chart_parts/TooltipCustomized_html.svelte";
  // import AxisX from './chart_parts/AxisX.svelte';
  // import AxisY from './chart_parts/AxisY.svelte';

  // export let showLabels = true;

  export let data;
  export let targetValue;
  export let actualValue;

  export let stroke = '#000'; //"#3c5c89";
  
  export let xKey = 'category';
  export let yKey = 'magnitude';
  export let yRangeKey = 'range';
  export let zKey = 'variable';

  export let xDomain = [...new Set(data.map(d => d[xKey]))] //= ["activity", "efficiency", "cleaner"];
  export let zDomain;

  export let tooltipUnits = '';
  export let tooltipVarName;
  const formatVal = formatDefaultVal; // d => d < 100 ? format(".1f")(d) : format(',')(d)
  export let formatTooltipValue = d => (isNaN(+d) ?  `${formatVal(d[0])} to ${formatVal(d[1])}` : formatVal(d)) + " " + tooltipUnits;

  export let annotatedTargetPrefix = '';
  export let annotatedTargetSuffix = '';

  const topPadding = 80;
  $: padding = { top: topPadding, bottom: 0, right: 60, left:  arrowPadding + arrowWidth/2 }; 
  
  $: arrowPadding = $mq.mobile ? 6 : 10;
  const arrowWidth = 13;


  const getBackgroundFills = d => d == "historical" ? '#F7F7F7': chartColours.neutrals.background;


  const getStartValue = values => values.find(d => d.effect == xDomain[0])["ghgStartEnd"][0]
  const getEndValue = values => values.find(d => d.effect == xDomain[xDomain.length - 1])["ghgStartEnd"][1]

// Further filter data for chart (only take those in zDomain)
  $: dataForChart = zKey && zDomain ? data.filter(d => (zDomain.indexOf(d[zKey]) != -1)) : data;
  $: groupedData = groups(dataForChart, d => d[zKey]).map(d => ({key: d[0], values: d[1]}));

  let zLookup = {};

  if (zKey){
    for (let val in chartColours.drivers){
      zLookup[val] = {
        stroke: chartColours.drivers[val] ? chartColours.drivers[val] : stroke,
        class: val
      };
    }
  } 

  $: getTargetFill= val => {
    if (val <= targetValue){
      return chartColours.targets['meet']
    } else {
      return chartColours.targets['miss']
    }
  }

  // If not covered by the data extent, make sure the target is the bottom of the chart
  $: dataExtent = extent(merge(data.map(d => d[yRangeKey]).filter(d => d != null))) // use data that includes all scenarios 
  $: yDomain = dataExtent[0] > targetValue ? [targetValue, dataExtent[1]] : [dataExtent[0], Math.max(targetValue, dataExtent[1])]

  $: middleSpace = $mq.mobile ? 120 : 200; // in pixels
  const getGroupWidth = width => (width - middleSpace) / 2;

  // Tooltips
  let evt;
  let hideTooltip = true;

  const formatTooltipTitle = d => d == 'historical' ? `${years.historical[0]} - ${years.historical[1]}` : d.includes('ERP') ? `${years.projection[0]} - ${years.projection[1]}` : d

  $: formatTooltipKey = d => `${tooltipVarName} ${langHandler.getTranslation('attributed_to')} ${langHandler.getTranslation(d)?.toLowerCase()}`
  
</script>

<!-- Using the y and r dimension for y0, y1 -->
<div class="chart-container">
    <LayerCake
      data={groupedData}
      flatData={dataForChart}
      padding={padding}
      x={xKey}
      y={d => d[yRangeKey][0]}
      z={zKey}
      r={d => d[yRangeKey][1]}
      xScale={scalePoint()}
      yScale= {scaleLinear()}
      zScale={scalePoint()}
      xDomain={xDomain}
      yDomain={yDomain} 
      zDomain={zDomain}
      xRange={ ({ width, height }) => [arrowPadding, getGroupWidth(width) - arrowPadding] }
      zRange={ ({ width, height }) => [0, width - getGroupWidth(width)] }
      custom={{zLookup: zLookup, opacity: 0.8, arrowPadding, arrowWidth, bgPad: arrowPadding + arrowWidth/2, middleSpace,
        getBackgroundFills, getTargetFill, getStartValue, getEndValue, yKey}}
    >
      <Svg>
        <ArrowDots {targetValue} {actualValue}/>
        <Arrow 
          on:mousemove={event => evt = hideTooltip = event}
          on:mouseout={() => hideTooltip = true}/>
        <!-- <AxisY textAnchor={'end'} tickMarks={true} dyTick={4} dxTick={yAxisLabelOffset} /> -->
        <!-- <AxisY textAnchor={'end'} tickMarks={false} dxTick={-padding.left} formatTick={d => ""} ticks={[actualValue]}/> -->
      </Svg>
      <!-- Need "pointer events" to be false so tooltip mouse handlers work -->
      <Html pointerEvents={false}>
        <ArrowLabels getNames={d => langHandler.trimArticle(langHandler.getTranslation(d))}/>
        <ArrowAnnotations {targetValue} {actualValue} targetSuffix={annotatedTargetSuffix} targetPrefix={annotatedTargetPrefix}/>
        <Tooltip {evt} visible={hideTooltip != true} 
          formatTitle={formatTooltipTitle} 
          formatValue={formatTooltipValue}
          formatKey={formatTooltipKey}
          footnote={langHandler.getTranslation('double_click_driver')}/>
      </Html>
    </LayerCake>
</div>


<style>
  /*
    The wrapper div needs to have an explicit width and height in CSS.
    It can also be a flexbox child or CSS grid element.
    The point being it needs dimensions since the <LayerCake> element will
    expand to fill it.
  */
  .chart-container {
    width: 100%;
    height: 450px;
    min-width: 300px;
  }
</style>


