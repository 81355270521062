<script>
  import mq from "@/stores/mq.js";
  import { format } from 'd3-format';

  import Annotations from './AnnotationsCustom_html.svelte';
  import { chartColours, years } from '@/constants.js';
  import * as langHandler from '@/utils/language-handler.js';
  

  // Import the getContext function from svelte
  import { getContext } from 'svelte';

  export let actualValue;
  export let targetValue;
  export let targetPrefix;
  export let targetSuffix;

  const { data, xGet, yGet, x, y, r, xScale, yScale, zScale, custom, xRange, height, padding } = getContext('LayerCake');

  console.log("Annotations")
  $: console.log(actualValue)
  $: console.log(targetValue)
  $: console.log(targetPrefix)
  $: console.log(targetSuffix)
</script>



{#each $data as group, n}
<!-- width:max-content is important here to avoid overflow -->
  <div class="group-annotations" style="transform: translate({$zScale(group.key)}px, 0);width: max-content;">
    <div class="group-label years {$mq.mobile ? 'smaller' : ''}" style="width:{$xRange[1] + $custom.bgPad *2 + $custom.arrowWidth/2}px; top:{-$padding.top +10}px; left:{-$custom.bgPad}px;">
      {#if group.key == "historical"}
        {langHandler.getTranslation('historical')}
        <span class="text-detail">{years.historical[0]} - {years.historical[1]}</span>
      {:else if group.key.includes("ERP")}
        {langHandler.getTranslation('projected')}
        <span class="text-detail">{years.projection[0]} - {years.projection[1]}</span>
      {/if}
    </div>

    {#if group.key.includes("ERP")}
      <div class="group-label target">
        <Annotations classes="tracker-text {$mq.mobile ? 'smaller' : ''}" annotations={[
          {
            xPx: $xRange[1] + $custom.bgPad + 12, y: targetValue,
            text: `<strong>${format(',.3s')(targetValue)}</strong> Mt`, 
            prefix: targetPrefix,
            suffix: targetSuffix,
            // subclasses: "white-background"
          },
          {
            xPx: $xRange[0] + $custom.bgPad + (actualValue > targetValue ? 8 : 0), y: actualValue > targetValue ? (actualValue + targetValue)/2 : targetValue,
            widthPx: $custom.middleSpace*0.6 - 2*($custom.bgPad),
            text: actualValue > targetValue ? `<strong>${format(',.1f')(actualValue - targetValue)}</strong> Mt` : langHandler.getTranslation('aligned')?.toUpperCase(), 
            suffix: actualValue > targetValue ?  langHandler.getTranslation('gap_to_get_on_track') :  langHandler.getTranslation('with_net_zero'),
            colour: $custom.getTargetFill(actualValue),
            align: "right"
          }
        ]}/>
      </div>
    {/if}
  </div>
{/each}

<style>
  .group-label {
    position: absolute;
    line-height: 1;
  }

  .years{
    text-transform: uppercase;
    font-size: 0.8rem;
    text-align: center;
  }

  .years.smaller {
    font-size: 0.65rem;
  }


  .text-detail {
    display: block;
    font-weight: 600; 
    white-space: nowrap;
  }

</style>