<script>
  import * as langHandler from '@/utils/language-handler.js';
  import mq from "@/stores/mq.js";
  import { onMount } from 'svelte';
  import sanitizeHtml from "sanitize-html";

  // Activity dashboard
  import ChartSection from "./ChartSection.svelte";
  import LineChart from "./Chart__Line.svelte";
  import ArrowChart from "./Chart__Arrow.svelte";
  import SectorDropdown from "./SectorDropdown.svelte"
  import ScenarioSelector from "./ScenarioSelector.svelte";
  import BottomNav from './BottomNav.svelte';
  import InfoTooltip from "./InfoTooltip.svelte";

  import { selectedSector, selectedProjection } from '@/stores/interactivity.js'
  import { chartColours, years, projectionKeys } from '@/constants.js';
  import { getKayaValue, formatDefaultVal, formatBigNumber, formatBigNumberLong, formatBigNumberDollars, formatBigNumberLongDollars, formatPercentageTracker, addNZPRange, usePopAsActivity } from "@/utils/dataHelpers.js"
  import { ghgData, kayaData, getValue } from '@/stores/data.js'

  let comparisonView = false;

  $: processGHGdata = (_, sector) => {
    let data = _.map(x => x).filter(d => d.sector === sector) // make a shallow copy
    // Create NZP_range with [lower, upper]
    data = addNZPRange(data, [emissionsIntensityKey, activityKey]);
    return data;
  }

  $: processedGHGData = $ghgData ? processGHGdata($ghgData, $selectedSector.id) : null
  $: projectionVars = comparisonView ? projectionKeys : [$selectedProjection.id]

  $: usePopulation = usePopAsActivity($selectedSector.id);
  $: activityKey = usePopulation ? "pop" : "gdp";

  console.log("Activity")

  $: console.log(usePopulation)
  $: console.log(activityKey)
  $: console.log($selectedSector.id)
  $: console.log($selectedProjection.id)
  // $: console.log($getValue("cement", 2020, d => d["ghg"], "ERP_legislated"))
  // $: console.log($getValue("cement", 2020, d => d["gdp"], "ERP_legislated"))
  // $: console.log($getValue("cement", 2020, d => d["pop"], "ERP_legislated"))
  // $: console.log("______")
  // $: console.log($getValue("bldg_res", 2020, d => d["ghg"], "ERP_legislated"))
  // $: console.log($getValue("bldg_res", 2020, d => d["gdp"], "ERP_legislated"))
  // $: console.log($getValue("bldg_res", 2020, d => d["pop"], "ERP_legislated"))
  $:console.log(getActivityValueByYr(2005))


  $: emissionsIntensityKey = usePopulation ? "ghg_per_pop" : "ghg_per_gdp"

  onMount(() => {
    const headerHeight = document.getElementsByClassName("site-header")[0].offsetHeight
    if (window.scrollY > headerHeight) {
      window.scrollTo(0,headerHeight);
    } 
	});

  const projectionStartYear = Number(years.projection[0])

  $: targetIntensityChange = ($getValue($selectedSector.id, 2030, d => d[emissionsIntensityKey], "NZP_upper") - getIntensityValueByYr(projectionStartYear))/ Math.abs(getIntensityValueByYr(projectionStartYear));
  $: actualIntensityChange= ($getValue($selectedSector.id, 2030, d =>d[emissionsIntensityKey], $selectedProjection.id) - getIntensityValueByYr(projectionStartYear))/ Math.abs(getIntensityValueByYr(projectionStartYear));

  $: getActivityValueByYr = year => $getValue(
    $selectedSector.id,
    year,
    d => d[activityKey], 
    year < years.projection[0] ? "historical" : $selectedProjection.id);

  $: formatActivityVal = usePopulation ? d => `${formatBigNumberLong(d)} ${langHandler.getTranslation('people')}` : d => formatBigNumberLongDollars(d)

  $: getIntensityValueByYr = year => $getValue($selectedSector.id, year, d => d[emissionsIntensityKey], year < years.projection[0] ? "historical" : $selectedProjection.id);
  $: formatIntensityVal = d => formatDefaultVal(d) + ' ' + (usePopulation ? langHandler.getUnits("ghg_per_pop_short") : langHandler.getUnits("ghg_per_gdp_short"))

  $: getKayaChange = (driver, endYear) => getKayaValue($kayaData, driver, endYear, $selectedSector.id, $selectedProjection.id)

  const formatChangeSentence = (d, formatVal=formatDefaultVal) =>  `${d >= 0 ? langHandler.getTranslation('increase_of') : langHandler.getTranslation('decrease_of')} ${formatVal(Math.abs(d))}`
</script>

<div class="text-wrapper">
  <div class="centered-text">
    {#if (langHandler.locale) == 'fr'}
      <h1 class="section-title fr">Le rôle de <span class="activity">l'activité</span> dans la réduction des émissions</h1>
      <p>Bien que les changements dans l’activité ne fassent pas partie des principaux objectifs des politiques environnementales, l’activité de certains secteurs a une influence sur les émissions de gaz à effet de serre. Selon le secteur, l’activité se mesure en produit intérieur brut (PIB) ou en population.</p>
      <p>La figure suivante montre les tendances rétrospectives du PIB ou de la population et leur évolution prévue au cours de la décennie.</p>
    {:else}
      <h1 class="section-title">Role of <span class="activity">activity</span> in reducing emissions</h1>
      <p>While changes in activity are not a primary objective of climate mitigation policy, activity in certain sectors can drive greenhouse gas emissions up or down. Depending on the sector, activity can be measured by Gross Domestic Product (GDP) or by population.</p>
      <p>Here, see how GDP or population has changed and is expected to change over the next decade.</p>
    {/if}
  </div>
</div>

<!-- In millions -->
<ChartSection title={langHandler.getTranslation('activity_for')} 
  units={usePopulation ? langHandler.getUnits('population') : langHandler.getUnits('gdp')} 
  chartOffsetLeft={$mq.laptop ? 0 : '3ch'}>
  <span slot="sector-dropdown">
    <SectorDropdown/>
  </span>
  <span slot="chart">
    {#if processedGHGData}
      <LineChart data={processedGHGData} 
        includesRanges={false}
        xKey={"year"}
        xDomain={[years.historical[0], years.projection[1]]}
        yKey={activityKey}
        stroke={chartColours.drivers['activity']}
        highlight={{fill: chartColours.drivers['activity'], opacity: 0.1}}
        zKey={"scenario"} 
        zDomain={["historical", ...projectionVars]} 
        formatAxisVal={usePopulation ? formatBigNumber : formatBigNumberDollars}
        formatTooltipVal={usePopulation ? formatBigNumberLong : formatBigNumberLongDollars}
        tooltipVarName={usePopulation ? langHandler.getTranslation("population") : langHandler.getTranslation("gdp")}
        />
        <!-- Omitted NZP from this figure, so the following line is not needed -->
        <!-- formatSpecial_NZP={{label: 'Assumption under Net Zero', opacity: 0.2, fill: chartColours.drivers['activity']}} -->
    {:else}
      <div class="loading-text">{langHandler.getTranslation('loading_chart')}</div>
    {/if}
  </span>
  <span slot="legend">
    <ScenarioSelector allowComparison=true bind:comparisonView/>
  </span>
  <span class="source" slot="source">
    Sources: <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('statscan')}>{langHandler.getTranslation('statscan')}</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.naviusresearch.com/">Navius Research</a>.
  </span>
  <span slot="caption">
    {#if langHandler.locale == 'fr'}
      {#if usePopulation}
        La population canadienne devrait atteindre <strong>{formatActivityVal(getActivityValueByYr(2030))} en 2030</strong>. 
        Dans {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))}, cela devrait entraîner une {getKayaChange("activity", 2030) > 0 ? 'augmentation' : 'diminution'} correspondante des émissions de {getKayaChange("activity", 2030)} Mt d'ici 2030.
      {:else}
        Selon le scénario des <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix} du Plan de réduction des émissions, le PIB {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector, true))} devrait atteindre <strong>{formatActivityVal(getActivityValueByYr(2030))} en 2030</strong> –
        {formatChangeSentence(getActivityValueByYr(2030) - getActivityValueByYr(projectionStartYear), formatActivityVal)} par rapport aux chiffres de {projectionStartYear}. 
        Selon les prévisions, cette {getActivityValueByYr(2030) - getActivityValueByYr(projectionStartYear) > 0 ? 'hausse' : 'baisse'} s’accompagnerait d’une {getKayaChange("activity", 2030) > 0 ? 'augmentation' : 'decrease'} de {getKayaChange("activity", 2030)} Mt  d’ici 2030.
      {/if}
    {:else}
      {#if usePopulation}
        Canada’s population is expected to reach <strong>{formatActivityVal(getActivityValueByYr(2030))} in 2030</strong>. 
        In {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))}, this is projected to lead to a corresponding emissions {getKayaChange("activity", 2030) > 0 ? 'increase' : 'decrease'} of {getKayaChange("activity", 2030)} Mt by 2030.
      {:else}
        Under the Emissions Reduction Plan’s <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix}, GDP in {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} is expected to reach <strong>{formatActivityVal(getActivityValueByYr(2030))} in 2030</strong>–
        {formatChangeSentence(getActivityValueByYr(2030) - getActivityValueByYr(projectionStartYear), formatActivityVal)} from {projectionStartYear} levels. 
        This is projected to lead to a corresponding emissions {getKayaChange("activity", 2030) > 0 ? 'increase' : 'decrease'} of {getKayaChange("activity", 2030)} Mt by 2030.
      {/if}
    {/if}
</span>
</ChartSection>

<div class="text-wrapper">
  <div class="centered-text">
    {#if (langHandler.locale) == 'fr'}
      <p>Toutefois, qui dit hausse de l’activité ne dit pas nécessairement hausse des émissions de gaz à effet de serre – les pays développés font des progrès dans le découplage de la croissance économique et des émissions, et le Canada n’est pas en reste.</p>
      <p>Le diagramme suivant montre l’évolution des émissions de carbone par unité d’activité (PIB ou population) au fil du temps.</p>
    {:else}
      <p>However, increases in activity don’t necessarily mean increases in greenhouse gas emissions—developed economies are making progress decoupling growth from emissions, and Canada is on track to do the same.</p>
      <p>The graph below shows how the carbon emissions per unit of activity (GDP or population) has changed over time.</p>
    {/if}
  </div>
</div>

<ChartSection title={langHandler.getTranslation('activity_intensity_for')} 
  units={usePopulation ? langHandler.getUnits("ghg_per_pop") : langHandler.getUnits("ghg_per_gdp")}
  chartOffsetLeft={$mq.laptop ? 0 : '3ch'}>
  <span slot="sector-dropdown">
    <SectorDropdown/>
  </span>
  <span slot="chart">
  <!-- Use a yAccessor instead of a key here -->
  {#if processedGHGData}
    <LineChart data={processedGHGData} 
      includesRanges={true}
      xKey={"year"}
      xDomain={[years.historical[0], years.projection[1]]}
      yKey={emissionsIntensityKey}
      targetValue={$getValue($selectedSector.id, 2030, d => d[emissionsIntensityKey], "NZP_upper")}
      actualValue={$getValue($selectedSector.id, 2030, d => d[emissionsIntensityKey], $selectedProjection.id)}
      stroke={chartColours.neutrals['line']}
      highlight={{fill: chartColours.neutrals['background'], opacity: 1}}
      zKey={"scenario"} 
      zDomain={["historical", "NZP_upper", "NZP_lower", "NZP_range", ...projectionVars]} 
      tooltipUnits={usePopulation ? langHandler.getUnits("ghg_per_pop_short") : langHandler.getUnits("ghg_per_gdp_short")}
      tooltipVarName={langHandler.getTranslation('activity_intensity')}
      />
  {:else}
    <div class="loading-text">{langHandler.getTranslation('loading_chart')}</div>
  {/if}
  </span>
  <span slot="legend">
    <ScenarioSelector allowComparison=true bind:comparisonView/>
  </span>
  <span class="source" slot="source">
    Sources: <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('statscan')}>{langHandler.getTranslation('statscan')}</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.naviusresearch.com/">Navius Research</a>.
  </span>
  <span slot="caption">
    {#if (langHandler.locale) == 'fr'}
      <p>
        Selon le scénario des <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix} du Plan de réduction des émissions, les émissions de gaz à effet de serre par {usePopulation ? "capita" : "PIB"} au {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} devraient 
        <strong>{getIntensityValueByYr(2030) - getIntensityValueByYr(projectionStartYear) >0 ? 'increase' : 'diminuer'} de {formatIntensityVal(Math.abs(getIntensityValueByYr(2030) - getIntensityValueByYr(projectionStartYear)))}</strong> de {projectionStartYear} à 2030. 
      </p>
      <p>
        Pour être en phase avec la trajectoire carboneutre, l’intensité des émissions doit diminuer de {formatPercentageTracker(-1*(targetIntensityChange))} entre {projectionStartYear} et 2030. 
        En s’appuyant sur les {$selectedProjection.name?.toLowerCase()}, on prévoit que l'intensité des émissions {actualIntensityChange >0 ? 'augmentera' : 'diminuera'} de {formatPercentageTracker(Math.abs(actualIntensityChange))}, 
        {#if actualIntensityChange > targetIntensityChange} 
          soit <span class="tracker-text" style='color:{chartColours.targets['miss']}'>un manque à gagner de {formatPercentageTracker(Math.abs(actualIntensityChange - targetIntensityChange))} par rapport à la trajectoire carboneutre</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip>
        {:else}
          ce qui le met <span class="tracker-text" style='color:{chartColours.targets['meet']}'>sur la voie d'une trajectoire carboneutre</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> 
        {/if}
      </p>
    {:else}
      <p>
        Under the Emissions Reduction Plan’s <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix}, greenhouse gas emissions per {usePopulation ? "capita" : "GDP"} for {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} is expected to
        <strong>{getIntensityValueByYr(2030) - getIntensityValueByYr(projectionStartYear) >0 ? 'increase' : 'decrease'} by {formatIntensityVal(Math.abs(getIntensityValueByYr(2030) - getIntensityValueByYr(projectionStartYear)))}</strong> from {projectionStartYear} to 2030. 
      </p>
      <p>
        To be aligned with a net zero pathway, emissions intensity must decrease by {formatPercentageTracker(-1*(targetIntensityChange))} between {projectionStartYear} and 2030. 
        With {$selectedProjection.name?.toLowerCase()}, emissions intensity is projected to {actualIntensityChange >0 ? 'increase' : 'decrease'} by {formatPercentageTracker(Math.abs(actualIntensityChange))}, putting it
        {#if actualIntensityChange > targetIntensityChange} 
          <span class="tracker-text" style='color:{chartColours.targets['miss']}'>{formatPercentageTracker(Math.abs(actualIntensityChange - targetIntensityChange))} behind the net zero pathway</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip>
        {:else}
          <span class="tracker-text" style='color:{chartColours.targets['meet']}'>on track with a net zero pathway</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> 
        {/if}
      </p>
    {/if}
</span>
</ChartSection>

<BottomNav page="activity"/>

<style>
  .report-name, .source a {
    font-style: italic;
  }

  ul li {
    margin-left: 1em;
  }

  .tracker-text {
    font-weight: 600;
  }

  .activity {
    color: var(--orange-medium);
  }

  .efficiency {
    color: var(--blue);
  }

  .cleaner {
    color: var(--turquoise-medium);
  }

  .text-wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  
  .text-wrapper {
    font-family: var(--serif);
  }

  h1, .section-title {
    font-family: var(--condensed);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3;
    color: var(--neutral);
  }

  .section-title {
    text-align: center;
    margin: 0 auto;
    margin-top: 32px;
  }

  .section-title.fr {
    max-width: 30ch;
  }
  
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .loading-text {
    height: 350px; /* same as chart-container */
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }
</style>
