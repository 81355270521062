<script>
  import { LayerCake, Svg, Html, flatten } from 'layercake';
  import { format } from 'd3-format';
  import { groups, range } from 'd3-array';
  import { stack } from 'd3-shape';
  import { scaleLinear, scaleOrdinal } from 'd3-scale';
  import mq from "@/stores/mq.js";
  import { chartColours, years } from '@/constants.js';
  import * as langHandler from '@/utils/language-handler.js';

  import RectangleHighlight from './chart_parts/RectangleHighlight.svelte';
  import AreaStacked from './chart_parts/AreaStacked.svelte';
  import SharedTooltip from './chart_parts/SharedTooltipCustomized_html.svelte';
  import Annotations from './chart_parts/AnnotationsCustom_html.svelte';
  import StackedLabels from './chart_parts/StackedLabels_html.svelte';

  import AxisX from './chart_parts/AxisX.svelte';
  import AxisY from './chart_parts/AxisY.svelte';

  export let yAxisLabelOffset = -4;
  export let topPadding = 30;
  export let padding = { top: topPadding, bottom: 20, right: 0, left:  - yAxisLabelOffset}; 
  export let formatTooltipValue = d => format(",.1%")(d);
  // export let showLabels = true;

  export let data;

  export let stroke = '#000'; //"#3c5c89";
  
  export let xKey = 'year';
  export let zKey = 'variable';
  export let seriesNames = Object.keys(data[0]).filter(d => d !== xKey);

  export let yDomain = [0, null];
  export let xDomain = [years.historical[0], years.projection[1]] // null // [0, null]
  export let zDomain;

  export let highlight = {fill: '#CCC', opacity: 1};

  let dataForChart = data;

  const stackData = stack().keys(seriesNames)
  let series;


  $:{ 
    // Further filter data for chart (only take those in zDomain)
    dataForChart = zKey && zDomain ? data.filter(d => (zDomain.indexOf(d[zKey]) != -1)) : data;
    series = stackData(dataForChart);
  }


  let zLookup = {};

  if (zKey){
    for (let val in chartColours.energy){
      zLookup[val] = {
        fill: chartColours.energy[val],
      };
    }
  } 

</script>

<div class="chart-container">
    <LayerCake
      data={series}
      flatData={flatten(series)}
      padding={padding}
      x={d => d.data[xKey]}
      y={[0, 1]}
      z={zKey}
      yScale= {scaleLinear()}
      zScale={scaleOrdinal()}
      yDomain={yDomain}
      xDomain={xDomain}
      custom={{zLookup: zLookup, opacity: 0.8, xKey, smallerAxisText: $mq.mobile}}
    >
    <Svg>
      <RectangleHighlight x0={years.projection[0]} x1={years.projection[1]} 
      fill={highlight.fill} opacity={highlight.opacity} linePlacement='none'/>
      <AxisX gridlines={false} formatTick={d => ""} ticks={range(xDomain[0], xDomain[1], 5)}/>
      <AxisX gridlines={false} snapTicks={true} baseline={true} ticks={xDomain[1] == 2050 ? [2005, 2023, 2030, 2050] : [2005, 2023, 2030]} boldTheseTicks={[2023, 2030]} dyTick={4}/>
      <AxisY textAnchor={'end'} tickMarks={true} formatTick={format(",.0%")}
        dyTick={4} dxTick={yAxisLabelOffset} gridlines={false}  
        removeFirstTick={true}/>
      <AreaStacked/>
      <RectangleHighlight x0={years.historical[0]} x1={years.projection[0]} 
        fill="#FFF" opacity="0.3" linePlacement="right"/>
    </Svg>

      <Html>
        <Annotations classes="years {$mq.mobile ? 'smaller' : ''}" annotations={[
          {x: years.historical[0], text: langHandler.getTranslation('historical'), width: years.historical[1] - years.historical[0]}, 
          {x: years.projection[0], text: langHandler.getTranslation('projected'), width: years.projection[1] - years.projection[0]}]} 
          yDefault={-topPadding*0.8}/>
        <StackedLabels formatKey={d => langHandler.getTranslation(d)}/>
          
        <SharedTooltip dataset={dataForChart}
          displayKey={d => ['share_emitting', 'share_nonemitting'].indexOf(d) > -1}
          tooltipX={d => d[xKey]}
          formatKey={d => langHandler.getTranslation(d)}
          formatValue={formatTooltipValue}
        />
      </Html>
    </LayerCake>
</div>


<style>
  /*
    The wrapper div needs to have an explicit width and height in CSS.
    It can also be a flexbox child or CSS grid element.
    The point being it needs dimensions since the <LayerCake> element will
    expand to fill it.
  */
  .chart-container {
    width: 100%;
    height: 350px;
  }
</style>


