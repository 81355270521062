<script>
  import * as langHandler from '@/utils/language-handler.js';
  import mq from "@/stores/mq.js";
  import { onMount } from "svelte";
  import sanitizeHtml from "sanitize-html";

  // Landing page and GHG overview
  import ChartSection from "./ChartSection.svelte";
  import LineChart from "./Chart__Line.svelte";
  import ArrowChart from "./Chart__Arrow.svelte";
  import SectorDropdown from "./SectorDropdown.svelte"
  import ScenarioSelector from "./ScenarioSelector.svelte";
  import KayaLegend from "./KayaLegend.svelte";
  import BottomNav from "./BottomNav.svelte";
  import InfoTooltip from "./InfoTooltip.svelte";

  import { selectedSector, selectedProjection } from '@/stores/interactivity.js'
  import { chartColours, years, projectionKeys } from '@/constants.js';
  import { getKayaValue, formatDefaultVal, addNZPRange } from "@/utils/dataHelpers.js"
  import { ghgData, kayaData, getValue } from '@/stores/data.js'

  import css from '@/utils/css.js'

  let comparisonView = false;
  let heroWidth;

  const processGHGdata = (_, sector) => {
    
    let data = _.map(x => x).filter(d => d.sector === sector) // make a shallow copy
    // Create NZP_range with [lower, upper]
    data = addNZPRange(data, "ghg");
    return data;
  }

  const kayaOrder = ["activity", "efficiency", "cleaner"]
  const scenarios = ["historical", "ERP_legislated", "ERP_developing", "ERP_announced_more", "ERP_announced_less"]

  $: processKayaData = (_, sector) => {
    let data = _.map(x => x).filter(d => d.sector === sector) // make a shallow copy
    for (const scen of scenarios){
      const startYears = [...new Set(data.filter(d => d.scenario == scen).map(d => d.startYear))]
      for (const year of startYears){
        const baseValue = $getValue(sector, year)
        // console.log(baseValue)
        let y0 = +baseValue
        for (const eff of kayaOrder){
          data.filter(d => d.effect == eff && d.scenario == scen && d.startYear == year).forEach((d) => {
            let y1 = y0 + d.ghgChange;
            d.ghgStartEnd = [y0, y1];
            // console.log({scen,year,eff,y0,y1})
            y0 = y1  // (There should only be one data point that matches the effect, scenario, and year)
          })
        }
      }
    }
    if(data[2].ghgStartEnd[1] == data[3].ghgStartEnd[0]){
    return data;
    }
    else{
      data[2].ghgStartEnd[1] = data[3].ghgStartEnd[0] 
      // console.log(data)
      return data;
    }
  }



  const projectionStartYear = Number(years.projection[0])
  $: processedGHGData = $ghgData ? processGHGdata($ghgData, $selectedSector.id) : null
  $: processedKayaData = $kayaData ? processKayaData($kayaData, $selectedSector.id) : null

  $: projectionVars = comparisonView ? projectionKeys : [$selectedProjection.id]
  // console.log($processKayaData)

  onMount(() => {
    const headerHeight = document.getElementsByClassName("site-header")[0].offsetHeight
    if (window.scrollY > headerHeight) {
      window.scrollTo(0,headerHeight);
    } 
	});



  $: targetValue = $getValue($selectedSector.id, 2030, d => d["ghg"], "NZP_upper");
  $: actualValue = $getValue($selectedSector.id, 2030, d => d["ghg"], $selectedProjection.id);
  
  $: getValueByYr = year => $getValue(
    $selectedSector.id, 
    year, 
    d => d["ghg"],
     year < years.projection[0] ? "historical" : $selectedProjection.id);
     
  $: getKayaChange = (driver, endYear) => getKayaValue($kayaData, driver, endYear, $selectedSector.id, $selectedProjection.id)


  const isCanada = d => (d.id.toLowerCase() == "canada" || d.id.toLowerCase() == "national")
  const formatChangeSentence = (d, formatVal=formatDefaultVal) =>  `${d >= 0 ? langHandler.getTranslation('increase_of') : langHandler.getTranslation('decrease_of')} ${formatVal(Math.abs(d))}`
 
  // console.log(getValueByYr(2030))
  // console.log(getValueByYr(projectionStartYear))

</script>

<div class="text-wrapper">
  <div class="centered-text">
    <div class="page-hero {$mq.classNames}" bind:clientWidth={heroWidth} use:css={ {"circle-size": `${heroWidth}px` }}>
      <div class="circle-accent" style="width:{heroWidth*0.75}px;height:{heroWidth*0.75}px;"></div>
      {#if langHandler.locale == 'fr'}
        <h1 class="fr">Pour atteindre la carboneutralité, le Canada doit d’abord ramener ses émissions annuelles de GES à <span class="highlight">440 mégatonnes</span> ou moins d’ici&nbsp;2030.</h1>
      {:else}
        <h1>The first step to reach Canada’s net zero goal is to reduce annual greenhouse gas emissions to <span class="highlight">440 megatonnes</span> or lower by&nbsp;2030.</h1>
      {/if}
    </div>
    <h2 class="hero-subtitle">{langHandler.getTranslation('hero_subtitle')}</h2>
    {#if langHandler.locale == 'fr'}
      <p>À l’aide des données nationales et sectorielles de son <a target="_blank" rel="noopener noreferrer" href="https://climateinstitute.ca/wp-content/uploads/2024/01/Evaluation-independante-plan-reduction-emissions-2023-rapport-etape.pdf">évaluation indépendante</a> du Rapport d’étape 2023 sur le Plan de réduction des émissions (PRE) du Canada et de son rapport de 2021 <a class="report-name" target="_blank" rel="noopener noreferrer" href="https://institutclimatique.ca/reports/vers-un-canada-carboneutre/">Vers un Canada carboneutre</a>, en plus des données de Statistique Canada, l’Institut climatique du Canada a dégagé les tendances rétrospectives et les prévisions d’émissions pour 2030 et 2050.</p>
      <p>Ces visualisations représentent les réductions d’émissions prévues en fonction des politiques légiférées, des politiques en cours d’élaboration et des politiques annoncées dans le Rapport d’étape 2023 sur le PRE, ainsi que leur alignement sur les trajectoires carboneutres potentielles d’ici 2050 et les domaines où il y a encore du travail à faire.</p>
      <p>Pour une liste des politiques intégrées dans chaque scénario, consulter le <a target="_blank" rel="noopener noreferrer" href="https://440megatonnes.ca/wp-content/uploads/2023/12/ERP-Policy-Characterization-2023-12-08.pdf">rapport technique sur le PRE</a> de l’Institut climatique. La boussole des trajectoires a été alimentée en 2024 avec les nouvelles données et analyses tirées de notre deuxième évaluation indépendante des progrès du Canada dans sa réduction des émissions. Les données de notre <a target="_blank" rel="noopener noreferrer" href="https://climateinstitute.ca/wp-content/uploads/2022/04/ERP-Volume-2-French-FINAL.pdf">première évaluation indépendante</a> du PRE pour 2030 du Canada, menée en 2022, peuvent être téléchargées <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('downloadfile')}>ici</a>.</p>
    {:else}
      <p>Using national and sectoral data from the Canadian Climate Institute’s <a target="_blank" rel="noopener noreferrer" href="https://climateinstitute.ca/wp-content/uploads/2023/12/ERP-assessment-2023-EN.pdf">independent assessment</a> of Canada’s 2023 Emissions Reduction Plan (ERP) Progress Report and the Institute’s 2021 report <a class="report-name" target="_blank" rel="noopener noreferrer" href="https://climateinstitute.ca/reports/canadas-net-zero-future/">Canada’s Net Zero Future</a>, alongside data from Statistics Canada, we trace historical and projected emissions trends to 2030 and 2050. </p>
      <p>These visualizations indicate the emissions reductions expected from the legislated, developing, and announced policies in Canada’s 2023 ERP Progress Report, how these reductions align with Canada’s potential pathways to net zero by 2050, and where more work is required.</p>
      <p>For a list of included policies under each scenario, see the Institute’s <a target="_blank" rel="noopener noreferrer" href="https://440megatonnes.ca/wp-content/uploads/2023/12/ERP-Policy-Characterization-2023-12-08.pdf">ERP technical report</a>. This dashboard was updated in 2024 with new data and analysis from our second independent assessment of Canada’s emissions reduction progress. Data from our <a target="_blank" rel="noopener noreferrer" href="https://climateinstitute.ca/wp-content/uploads/2022/04/ERP-Volume-2-FINAL.pdf">first independent assessment</a> of Canada’s 2030 ERP, completed in 2022, can be downloaded <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('downloadfile')}>here</a>.</p>
    {/if}
  </div>
</div>

<ChartSection title={langHandler.getTranslation('ghg_for')} 
  units={langHandler.getUnits('ghg')} chartOffsetLeft={$mq.laptop ? 0 : '3ch'}>
  <span slot="sector-dropdown">
    <SectorDropdown/>
  </span>
  <span slot="chart">
    {#if processedGHGData}
    <LineChart data={processedGHGData} 
      includesRanges={true}
      xKey={"year"}
      xDomain={[years.historical[0], years.netZero[1]]}
      yKey={"ghg"}
      stroke={chartColours.neutrals['line']}
      highlight={{fill: chartColours.neutrals['background'], opacity: 1}}
      targetValue={targetValue}
      actualValue={actualValue}
      zKey={"scenario"} 
      zDomain={["historical", "NZP_upper", "NZP_lower", "NZP_range", ...projectionVars]} 
      tooltipUnits={langHandler.getUnits('ghg_short')}
      tooltipVarName={langHandler.getTranslation('ghg')}
      showAnnotations={!comparisonView}
      />
    {:else}
      <div class="loading-text">{langHandler.getTranslation('loading_chart')}</div>
    {/if}
  </span>
  <span slot="legend">
    <ScenarioSelector allowComparison=true bind:comparisonView/>
  </span>
  <span class="source" slot="source">
    Sources: <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('statscan')}>{langHandler.getTranslation('statscan')}</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.naviusresearch.com/">Navius Research</a>, <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('cci')}>{langHandler.getTranslation('cci')}</a>.
  </span>
  <span slot="caption">
    {#if langHandler.locale == 'fr'}
      Selon le scénario des politiques <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix} du Plan de réduction des émissions, 
      {#if actualValue > targetValue}
        {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} émettra {formatDefaultVal(actualValue - targetValue)} mégatonnes de gaz à effet de serre <span class="tracker-text" style='color:{chartColours.targets['miss']};margin-right:2px;'>de trop par rapport à la trajectoire carboneutre</span><InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> en 2030.
      {:else}
        on projette que les émissions de gaz à effet de serre dans {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} seront <span class="tracker-text" style='color:{chartColours.targets['meet']}'>sur la voie de la carboneutralité</span><InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> en 2030. 
      {/if}
    {:else}
      Under the Emissions Reduction Plan’s <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix}, greenhouse gas emissions in {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} are projected to be 
      {#if actualValue > targetValue}
        <span class="tracker-text" style='color:{chartColours.targets['miss']};margin-right:2px;'>behind the net zero pathway</span><InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> by {formatDefaultVal(actualValue - targetValue)} megatonnes in 2030.
      {:else}
        <span class="tracker-text" style='color:{chartColours.targets['meet']}'>on track with a net zero pathway</span><InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> in 2030. 
      {/if}
    {/if}
  </span>
</ChartSection>

<div class="text-wrapper">
  <div class="centered-text">
    {#if langHandler.locale == 'fr'}
      <h2 class="section-title">Qu'est-ce qui influence la réduction des émissions?</h2>
      <p style="margin-bottom: 0.5rem;">Pour mieux comprendre la progression vers la carboneutralité, on peut l’envisager comme le résultat de l’interaction de trois facteurs :</p>
        <ul>
          <li><strong>activité</strong> – le volume de production économique mesuré en PIB ou en population;</li>
          <li><strong>efficacité</strong> – l’énergie consommée par unité d’activité;</li>
          <li><strong>décarbonisation</strong> – la quantité de carbone totale émise par unité d’énergie consommée.</li>
        </ul>
      <p>Dans un contexte de croissance économique et démographique au Canada dans les prochaines années, la décarbonisation et l’efficacité énergétique seront sources de progrès dans l’optique d’atteindre 440&nbsp;Mt de réduction d’ici 2030 et la carboneutralité par la suite.</p>
    {:else}
      <h2 class="section-title">What’s driving emissions reductions?</h2>
      <p style="margin-bottom: 0.5rem;">Progress towards net zero can best be understood as resulting from the interaction among three drivers:</p>
        <ul>
          <li><strong>activity</strong>—the amount of economic production measured in GDP or population;</li>
          <li><strong>efficiency</strong>—the energy consumed per unit of activity; and </li>
          <li><strong>decarbonization</strong>—the amount of total carbon released per unit of energy used.</li>
        </ul>
      <p>As Canada’s economy and population continue to grow over the coming years, decarbonization and energy efficiency will drive progress towards 440&nbsp;Mt by 2030 and net zero beyond.</p>
    {/if}
  </div>
</div>

<ChartSection title={langHandler.getTranslation('ghg_change_for')} chartOffsetRight={$mq.laptop ? '16px' : 0}>
  <span slot="sector-dropdown">
    <SectorDropdown/>
  </span>
  <span slot="chart">
    {#if processedKayaData}
    <ArrowChart data={processedKayaData} 
      xKey={"effect"}
      yKey={"ghgChange"}
      yRangeKey={"ghgStartEnd"}
      xDomain={kayaOrder}
      targetValue={targetValue}
      actualValue={actualValue}
      zKey={"scenario"} 
      zDomain={["historical", $selectedProjection.id]} 
      tooltipUnits={langHandler.getUnits('ghg_short')}
      tooltipVarName={langHandler.getTranslation('ghg_change')}
      annotatedTargetSuffix={isCanada($selectedSector) ? langHandler.getTranslation('target') : ''}
    />
    {:else}
      <div class="loading-text">{langHandler.getTranslation('loading_chart')}</div>
    {/if}
  </span>
  <span slot="descriptions">
    <KayaLegend/>
  </span>
  <span slot="legend">
    <ScenarioSelector/>
  </span>
  <span slot="caption">
    {#if langHandler.locale == 'fr'}
      <p>De 2005 à {projectionStartYear}, {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector)) + (isCanada($selectedSector) ? '' : ' au Canada')} a connu une <strong>{getValueByYr(projectionStartYear) - getValueByYr(2005) > 0 ? 'hausse' : 'baisse'} globale</strong> des émissions de carbone de {formatDefaultVal(Math.abs(getValueByYr(projectionStartYear) - getValueByYr(2005)))} Mt, 
        passant de {formatDefaultVal(getValueByYr(2005))} Mt à {formatDefaultVal(getValueByYr(projectionStartYear))} Mt. 
        Avec les <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix} du Plan de réduction des émissions, nous projetons qu'entre {projectionStartYear} à 2030, {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} connaîtra :</p>
      <ul>
        <li><span class="tracker-text activity">{formatChangeSentence(getKayaChange("activity", 2030))} Mt</span> due à une {getKayaChange("activity", 2030) > 0 ? 'augmentation' : 'diminution'} de l'activité;</li>
        <li><span class="tracker-text efficiency">{formatChangeSentence(getKayaChange("efficiency", 2030))} Mt</span> due à une {getKayaChange("efficiency", 2030) > 0 ? 'moins grande' : 'plus grande'} efficacité énergétique;</li>
        <li><span class="tracker-text cleaner">{formatChangeSentence(getKayaChange("cleaner", 2030))} Mt</span> due à {getKayaChange("cleaner", 2030) > 0 ? "l'accroissement" : 'la réduction'} de l'intensité carbonique;</li>
        <li>soit une <span class="tracker-text">{getValueByYr(2030) - getValueByYr(projectionStartYear) > 0 ? 'augmentation' : 'diminution'} nette de {formatDefaultVal(Math.abs(getValueByYr(2030) - getValueByYr(projectionStartYear)))} Mt</span> (de {formatDefaultVal(getValueByYr(projectionStartYear))} à {formatDefaultVal(getValueByYr(2030))} Mt).</li>
      </ul>
    {:else}
      <p>From 2005 to {projectionStartYear}, Canada saw an <strong>overall {getValueByYr(projectionStartYear) - getValueByYr(2005) > 0 ? 'increase' : 'decrease'}</strong> of {formatDefaultVal(Math.abs(getValueByYr(projectionStartYear) - getValueByYr(2005)))} Mt 
        in carbon emissions{@html isCanada($selectedSector) ? '' : ' from ' + sanitizeHtml(langHandler.formatSectorSentence($selectedSector))}, from {formatDefaultVal(getValueByYr(2005))} Mt to {formatDefaultVal(getValueByYr(projectionStartYear))} Mt. 
        With the <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix} from the Emissions Reduction Plan, we project that between {projectionStartYear} and 2030, Canada will see:</p>
      <ul>
        <li><span class="tracker-text activity">{formatChangeSentence(getKayaChange("activity", 2030))} Mt</span> due to {getKayaChange("activity", 2030) > 0 ? 'increases' : 'decreases'} in activity,</li>
        <li><span class="tracker-text efficiency">{formatChangeSentence(getKayaChange("efficiency", 2030))} Mt</span> due to {getKayaChange("efficiency", 2030) > 0 ? 'less' : 'more'} energy efficiency and</li>
        <li><span class="tracker-text cleaner">{formatChangeSentence(getKayaChange("cleaner", 2030))} Mt</span> due to {getKayaChange("cleaner", 2030) > 0 ? 'increased' : 'reduced'} carbon intensity</li>
        <li>for a <span class="tracker-text">net {getValueByYr(2030) - getValueByYr(projectionStartYear) > 0 ? 'increase' : 'decrease'} of {formatDefaultVal(Math.abs(getValueByYr(2030) - getValueByYr(projectionStartYear)))} Mt</span> (from {formatDefaultVal(getValueByYr(projectionStartYear))} to {formatDefaultVal(getValueByYr(2030))} Mt).</li>
      </ul>
    {/if}
  </span>
  
</ChartSection>


 <div class="text-wrapper">
  <div class="centered-text">
    {#if langHandler.locale == 'fr'}
      <p>Poursuivez la lecture pour en savoir plus sur chacun de ces facteurs.</p>
    {:else}
      <p>Continue to the next section to learn about each of these drivers.</p>
    {/if}
  </div>
</div>


<BottomNav page="overview"/>
<style>

  .report-name, .source a {
    font-style: italic;
  }

  ul li {
    margin-left: 1em;
  }

  .tracker-text {
    font-weight: 600;
  }

  .activity {
    color: var(--orange-medium);
  }

  .efficiency {
    color: var(--blue);
  }

  .cleaner {
    color: var(--turquoise-medium);
  }

  .text-wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  
  .text-wrapper {
    font-family: var(--serif);
  }

  h1, .section-title {
    font-family: var(--condensed);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3;
    color: var(--neutral);
  }

  .hero-subtitle {
    font-family: var(--sans);
  }

  .section-title {
    text-align: center;
    margin-top: 32px;
  }
  
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .page-hero {
    margin-top: 100px;
    position: relative;
    display: block;
  }

  .page-hero.mq-mobile {
    margin-top: 32px;
  }
  .page-hero .highlight {
    color: var(--red);
    white-space: nowrap;
  }

  .page-hero h1.fr {
    max-width: 30ch;
  }

  .text-wrapper * {
    position: relative; /* To position in front of circle */
  }

  .circle-accent {
    position: absolute;
    background-color: white;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    top: 100%;
    top: calc(var(--circle-size)*0.25);
    left: 50%;
  }

  .loading-text {
    height: 350px; /* same as chart-container */
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }

</style>
